<template>
  <div>
    <v-btn x-small class="mx-2 mb-2" dark color="primary" @click="addColumn('Status')"
      >ADD STATUS COLUMN</v-btn
    >
    <v-btn x-small class="mx-2 mb-2" dark color="primary" @click="addColumn('Responsible')"
      >ADD RESPONSIBLE COLUMN</v-btn
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import FulfilmentsUtil from "../utils/fulfilments-util";
export default {
  data() {
    return {
      stepOffset: 3,
      nextColumnName: this.getNextColumnName("Status"),
      rules: {
        counter: (value) => value.length <= 20 || "Max 20 characters",
        required: (value) => !!value || "Required.",
        alreadyExist: (value) => {
          return (
            !this.headers.find((header) => {
              return value.toLowerCase() === header.text.toLowerCase();
            }) || "Column name already exist"
          );
        },
      },
    };
  },
  methods: {
    getNextColumnName(columnType) {
      return this.$store.getters.getNextColumnName(columnType);
    },
    addColumn(columnType) {
      this.nextColumnName = this.getNextColumnName(columnType);
      if (this.rules.required(this.nextColumnName) === true && this.rules.alreadyExist(this.nextColumnName) === true) {
        if (columnType === "Status") {
          this.addStatusColumn();
        } else {
          this.addResponsibleColumn();
        }

        this.$store.dispatch("setHeaders", this.headers);
        this.$store.dispatch("setDeliverables", this.deliverables);
        this.$store.dispatch("setNextColumnName", columnType);
      }
    },
    addStatusColumn() {
      let stepLength = this.steps.length;
      let stepText = this.nextColumnName;
      let stepIdentity = `step${stepLength + 1}`;
      //Add new added step to headers array
      this.headers.splice(this.headers.length - 1, 0, {
        text: stepText,
        value: stepIdentity,
        editable: true,
        sortable: false,
        headerEditable: true,
        columnDeleteIcon: false,
      });

      this.steps.push({ text: stepText, identity: stepIdentity });
      //Change all row to have new added column value as 'open'
      this.deliverables.forEach((item) => {
        //This should be used instead of the above commented array form to add reactivity to all newly added column cells.
        if (item.isGrouped) {
          item.children.map((child) => {
            this.$set(child, stepIdentity, "open");
          });
        } else {
          this.$set(item, stepIdentity, "open");
        }
      });

      const util = new FulfilmentsUtil();
      util.recalculateAllFulfillments(this.deliverables, this.steps);

      this.$store.dispatch("setSteps", this.steps);
    },
    addResponsibleColumn() {
      let responsiblesLength = this.responsibles.length;
      let responsiblesText = this.nextColumnName;
      let responsibleIdentity = this.$store.getters.nextResponsibleIdentity;

      this.headers.splice(responsiblesLength + this.stepOffset, 0, {
        text: responsiblesText,
        value: responsibleIdentity,
        editable: true,
        sortable: false,
        headerEditable: true,
        columnDeleteIcon: false,
      });

      this.responsibles.push({
        text: responsiblesText,
        identity: responsibleIdentity,
      });

      this.$store.dispatch("setResponsibles", this.responsibles);
    },
  },

  computed: {
    ...mapState(["statusTable"]),
    headers() {
      return this.statusTable.headers;
    },
    deliverables() {
      return this.statusTable.deliverables;
    },
    responsibles() {
      return this.statusTable.responsibles;
    },
    steps() {
      return this.statusTable.steps;
    },
  },
};
</script>
