export default class FulfilmentsUtil {
  constructor() { }

  calculateRowFulfillment(row, steps) {
    let stepsLength = steps.length;
    let closedCount = 0;
    let wipCount = 0;
    let nrelCount = 0;

    steps.forEach(step => {
      closedCount += row[step.identity] === "closed" ? 1 : 0;
      wipCount += row[step.identity] === "wip" ? 0.5 : 0;
      nrelCount += row[step.identity] === "n.rel." ? 1 : 0;
    });

    let effectiveStepsLength = stepsLength - nrelCount;
    let fulfillment;

    if (nrelCount === stepsLength) {
      // If all statuses are "n.rel.", set fulfillment to "n.rel."
      fulfillment = "n.rel.";
    } else {
      // Calculate fulfillment normally
      fulfillment = ((closedCount + wipCount) / effectiveStepsLength) * 100;
    }

    row.fulfillment = fulfillment;
  }

  recalculateAllFulfillments(deliverables, steps) {
    // Recalculate for all rows
    deliverables.forEach(item => {
      this.calculateRowFulfillment(item, steps);
      if (item.isGrouped) {
        item.children.forEach((child) => {
          this.calculateRowFulfillment(child, steps);
        });

        // Aggregate children's fulfillment for the parent item
        this.calculateParentFulfillment(item, steps);
      }
    });
  }

  calculateParentFulfillment(row, steps) {
    // let totalFulfillment = 0;
    // let childCount = parent.children.length;

    // parent.children.forEach(child => {
    //   if (child.fulfillment !== "n.rel.") {
    //     totalFulfillment += child.fulfillment;
    //   }
    // });

    // // If all children are "n.rel.", the parent should also be "n.rel."
    // if (childCount > 0 && totalFulfillment === 0) {
    //   parent.fulfillment = "n.rel.";
    // } else {
    //   parent.fulfillment = totalFulfillment / childCount;
    // }
    if (steps.length > 0) {
      let totalWeights = 0;
      let sumAll = 0;

      row.children.forEach((item, idx) => {
        // const selectedRow = this.deliverables[idx];
        if (typeof item.fulfillment === "number") {
          totalWeights += +item.weight;
          sumAll += item.fulfillment * item.weight;
        }
      });
      row.fulfillment = sumAll / totalWeights;
    }
  }
}