const state = {
  headers: [
    {
      text: "No",
      align: "start",
      value: "id",
      sortable: true,
      editable: false,
      headerEditable: false,
      fixed: true,
    },
    { text: "Description", value: "description", editable: true, fixed: true, },
    { text: "Weight", value: "weight", editable: true },
    {
      text: "Step1",
      value: "step1",
      sortable: false,
      editable: true,
      headerEditable: true,
      // Added by MMG
      columnDeleteIcon: false
    },

    { text: "Fulfillment", value: "fulfillment", editable: false },

  ],
  deliverables: [],
  steps: [{ text: "Step1", identity: "step1" }],
  responsibles: [],
  nextColumnName: "Step2",
  newRowCounter: 0,
};

const mutations = {
  incrementNewRowCounter(state) {
    state.newRowCounter++;
  },
  SET_ROW_COUNTER_TO_ZERO(state, value) {
    state.newRowCounter = value;
  },
  SET_HEADERS(state, headers) {
    state.headers = headers;
  },
  SET_TABLE_STATE(state, deliverables) {
    state.deliverables = deliverables;
  },
  SET_STEPS(state, steps) {
    state.steps = steps;
    state.nextStatusColumn = "Step" + (state.steps.length + 1);
  },
  SET_RESPONSIBLES(state, responsibles) {
    state.responsibles = responsibles;
  },
  SET_NEXT_COLUMN_NAME(state, nextColumnName) {
    state.nextColumnName = nextColumnName;
  },
  LOAD_DELIVERABLES(state, loadedArray) {
    if (state.steps.length > 0) {
      state.deliverables = loadedArray;
    }
  },
};

const actions = {
  incrementNewRowCounterAction({ commit }) {
    commit('incrementNewRowCounter');
  },
  setRowCounterToZero({ commit }, value) {
    commit('SET_ROW_COUNTER_TO_ZERO', value)
  },
  setHeaders: ({ commit }, headers) => {
    commit("SET_HEADERS", headers);
  },
  setDeliverables: ({ commit }, deliverables) => {
    commit("SET_TABLE_STATE", deliverables);
  },
  setSteps: ({ commit }, steps) => {
    commit("SET_STEPS", steps);
  },
  setResponsibles: ({ commit }, responsibles) => {
    commit("SET_RESPONSIBLES", responsibles);
  },
  setNextColumnName: ({ commit, state }, columnType) => {
    let nextColumnName = "Step";
    if (columnType === "Status") {
      nextColumnName = "Step" + (state.steps.length + 1);
    } else if (columnType === "Responsible") {
      if (state.responsibles.length === 0) {
        nextColumnName = "Responsible";
      } else {
        nextColumnName = "Responsible" + (state.steps.length + 1);
      }
    }
    commit("SET_NEXT_COLUMN_NAME", nextColumnName);
  },
  loadDeliverables: ({ commit }, loadedArray) => {
    commit("LOAD_DELIVERABLES", loadedArray);
  }
};

const getters = {
  getNewRowCounter(state) {
    return state.newRowCounter;
  },
  getNextColumnName: state => columnType => {
    let nextColumnName = "Step";
    if (columnType === "Status") {
      nextColumnName = "Step" + (state.steps.length + 1);
    } else if (columnType === "Responsible") {
      if (state.responsibles.length === 0) {
        nextColumnName = "Responsible1";
      } else {
        nextColumnName = "Responsible" + (state.responsibles.length + 1);
      }
    }
    return nextColumnName; ب
  },
  headers: state => {
    return state.headers;
  },
  deliverables: state => {
    return state.deliverables;
  },
  steps: state => {
    return state.steps;
  },
  responsibles: state => {
    return state.responsibles;
  },

  stepsFulfillment: state => {

    if (!state.steps || state.steps.length === 0) {
      return {}; // or return some default value
    }

    let fulfillmentsObj = {};

    state.steps.forEach(step => {
      if (step) {
        let totalWeights = 0;
        let wipCount = 0;
        let closedCount = 0;
        state.deliverables.forEach(item => {

          if (item && item.isChild == true) {
            item.children.forEach((row) => {
              if (row[step.identity] !== "n.rel.") {
                totalWeights += +row.weight;
              }
              closedCount += row[step.identity] === "closed" ? 1 * row.weight : 0;
              wipCount += row[step.identity] === "wip" ? 0.5 * row.weight : 0;
            })
          }
          else {
            if (item[step.identity] !== "n.rel.") {
              totalWeights += +item.weight;
            }
            closedCount += item[step.identity] === "closed" ? 1 * item.weight : 0;
            wipCount += item[step.identity] === "wip" ? 0.5 * item.weight : 0;
          }
        });

        fulfillmentsObj[step.identity] =
          ((closedCount + wipCount) / totalWeights) * 100;
      }
    });
    // console.log('full fillment', fulfillmentsObj)
    return fulfillmentsObj;
  },

  subProjectStepsFullFilment: state => {
    if (!state.steps || state.steps.length === 0) {
      return {}; // or return some default value
    }

    console.log('sub project steps full')

    let fulfillmentsObj = {};

    state.deliverables.forEach(item => {
      if (item && item.isGrouped) {
        fulfillmentsObj[item.id] = {};

        state.steps.forEach(step => {
          if (step) {
            let totalWeights = 0;
            let wipCount = 0;
            let closedCount = 0;

            item.children.forEach(row => {
              if (row[step.identity] !== "n.rel.") {
                totalWeights += +row.weight;
              }
              closedCount += row[step.identity] === "closed" ? 1 * row.weight : 0;
              wipCount += row[step.identity] === "wip" ? 0.5 * row.weight : 0;
            });

            if (totalWeights === 0) {
              // Set to 0% if all statuses are "n.rel."
              fulfillmentsObj[item.id][step.identity] = 0;
            } else {
              fulfillmentsObj[item.id][step.identity] =
                ((closedCount + wipCount) / totalWeights) * 100;
            }
          }
        });
      }
    });
    return fulfillmentsObj;
  },

  totalFulfillment: state => {

    if (state.steps.length === 0) {
      return /* some default value or indication */;
    }

    let sumAll = 0;
    let totalWeights = 0;
    state.deliverables.forEach(item => {
      if (item && item.isChild == true) {
        item.children.forEach((row) => {
          if (typeof row.fulfillment === "number") {
            totalWeights += +row.weight;
            sumAll += row.fulfillment * row.weight;
          }
        })
      } else {
        if (typeof item.fulfillment === "number") {
          totalWeights += +item.weight;
          sumAll += item.fulfillment * item.weight;
        }
      }
    });

    if (totalWeights === 0) {
      return 0; // or return some default value
    }

    return sumAll / totalWeights;
    // }
  },

  nextResponsibleIdentity: state => {
    if (state.responsibles.length === 0) {
      return "responsible1";
    } else {
      return "responsible" + (state.responsibles.length + 1);
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
