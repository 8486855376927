<template>
  <v-progress-linear :value="value" :color="color" :height="height" reactive striped>
    <slot></slot>
  </v-progress-linear>
</template>

<script>
export default {
  props: ["value", "color", "height", "reactive","striped"],
};
</script>

<style></style>
