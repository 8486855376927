<template>
  <!-- Step's header edit dialog -->
  <v-edit-dialog
    class=""
    v-if="header.headerEditable"
    @open="headerTextFieldOpen(header.text)"
    @save="changeHeader(header)"
  >
    <!-- step title -->
    <span>
      <span>
        {{ header.text }}
      </span>
      <!-- edit text field -->
    </span>
    <template v-slot:input>
      <v-text-field
        @focus="$event.target.select()"
        :value="header.text"
        @change="inputChanged($event)"
        :rules="[rules.required, rules.alreadyExist]"
        counter
        maxlength="20"
        label="Edit"
        single-line
      ></v-text-field>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  props: ["header"],
  emits: ["handleHeaderTextFileOpend", "handleChangeHeader", "handleInputChange"],
  data: () => ({
    rules: {
      counter: (value) => value.length <= 20 || "Max 20 characters",
      required: (value) => !!value || "Required.",
      positiveNumbers: (value) => value > 0 || "Should be greater than 0",
      alreadyExist: true,
    },
  }),
  methods: {
    headerTextFieldOpen(headerText) {
      this.$emit("handleHeaderTextFileOpend", headerText);
    },
    changeHeader(header) {
      this.$emit("handleChangeHeader", header);
    },
    inputChanged(value, param) {
      this.$emit("handleInputChange", value, param);
    },
  },
};
</script>

<style></style>
