<template>
  <div class="backdrop" @click.self="closePopup('cancel')">
    <div class="popup">
      <h3>{{ message }}</h3>
      <v-btn color="red darken-1" text @click="closePopup('cancel')"
        >Cancel</v-btn
      >
      <v-btn color="green darken-1" text @click="closePopup('yes')">Yes</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ["message", "pressedType"],
  methods: {
    closePopup(answer) {
      if (answer === "cancel") {
        this.$emit("dontDelete");
      } else if (answer === "yes") {
        if (this.pressedType === "Row") {
          this.$emit("deleteRow");
        } else if (this.pressedType === "step") {
          this.$emit("deleteStep");
        } else if (this.pressedType === "responsible") {
          this.$emit("deleteResponsible");
        }
      }
    }
  }
};
</script>


