<template>
  <v-app>
    <v-main>
      <StatusTable />
    </v-main>
  </v-app>
</template>

<script>
import StatusTable from "./components/StatusTable";


export default {
  name: "App",
  created() {
    document.title = "Earned Value";
  },

  components: {
    StatusTable
  },

  data: () => ({
    drawer: false
    //
  })
};
</script>
