<template>
  <v-edit-dialog
    @close="close1(item)"
    @cancel="cancel"
    @open="open(item[header.value])"
    @save="changeCell(item, header.value)"
  >
    {{ item[header.value] }}
    <template v-slot:input>
      <span>
        <v-text-field
          type="number"
          :rules="[rules.required, rules.positiveNumbers]"
          v-if="header.text === 'Weight'"
          @focus="$event.target.select()"
          :value="item[header.value]"
          @change="inputChanged($event, 'weight')"
          label="Edit"
          single-line
        ></v-text-field>
        <v-text-field
          v-else
          @focus="$event.target.select()"
          :value="item[header.value]"
          @change="inputChanged($event)"
          label="Edit"
          single-line
        ></v-text-field>
      </span>
    </template>
  </v-edit-dialog>
</template>

<script>
export default {
  props: ["header", "item"],
  data: () => ({
    rules: {
      counter: (value) => value.length <= 20 || "Max 20 characters",
      required: (value) => !!value || "Required.",
      positiveNumbers: (value) => value > 0 || "Should be greater than 0",
      alreadyExist: true,
    },
  }),
  emits: ["handleClose1", "handleOpen", "handleChangeCell", "handleInputChanged"],
  methods: {
    close() {},
    cancel() {},
    close1(item) {
      this.$emit("handleClose1", item);
    },
    open(openedCell) {
      this.$emit("handleOpen", openedCell);
    },
    changeCell(item, headerValue) {
      this.$emit("handleChangeCell", item, headerValue);
    },
    inputChanged(value, param) {
      this.$emit("handleInputChanged", value, param);
    },
  },
};
</script>

<style></style>
